/* eslint-disable no-nested-ternary */
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid-pro';

import GridWithInlineEdit, { GridWithInlineEditRef } from 'ui-component/grids/GridWithInlineEdit';
import { getRenderCell, getRenderEditCellByType, getValueFormatter } from '../utils';
import { IRecordField } from '../types';
import { generateLineItemRows, getFieldsAsHeadersFields, getValueFromLineItemsData } from './utils';
import { ILineItemHeader, LineItemsFields } from './types';
import { CustomNoRowsOverlay } from './components';
import { getListIdsFromHeaders } from '../utils/headerHelpers';
import { useCustomListValues } from 'hooks/useCustomListValues';
import { useLineItemsData, useLineItemsMutations } from './hooks';
import { StripedDataGrid } from 'ui-component/grids';
import { useDocumentViewer } from 'views/DocumentViewer/hooks/useDocumentViewer';
import { Fab, Grid } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

export type LineItemsGridProps = {
    isOpen: boolean;
    recordId: number;
    headers?: ILineItemHeader;
    recordTypeId?: number;
    showCustomNoRowsOverlay?: boolean;
};

export type LineItemsGridRef = {
    handleAddClick: () => void;
};

export const LineItemsGrid = forwardRef(({ isOpen, recordId, headers, recordTypeId, showCustomNoRowsOverlay }: LineItemsGridProps, ref) => {
    const { handleSetLineItemUpdated } = useDocumentViewer();
    const gridRef = useRef<GridWithInlineEditRef | null>(null);
    const [newRows, setNewRows] = useState<Record<string, any>[]>([]);
    const { lineItemHeaders, getLineItems, lineItemsData, loading: loadingData } = useLineItemsData(recordTypeId as number, headers);

    const headerListIds = useMemo(
        () => getListIdsFromHeaders(getFieldsAsHeadersFields(lineItemHeaders?.lineItemsByTypeFileds)),
        [lineItemHeaders?.lineItemsByTypeFileds]
    );
    const { createLineItem, createOrUpdateByRow, loading: loadingMutations, updateLineItem } = useLineItemsMutations(recordId);
    const { loading: loadingListValues, listValuesByListId } = useCustomListValues(headerListIds);

    const headerList: GridColDef[] = useMemo(() => {
        if (!lineItemHeaders) return [];
        const sortedArr = [...lineItemHeaders.lineItemsByTypeFileds].sort((a, b) => a.order - b.order);

        return sortedArr.map(
            ({ recordAdditionalFields, order }) =>
                ({
                    field: `${recordAdditionalFields.name}-${order}`,
                    headerName: recordAdditionalFields.name,
                    editable: true,
                    valueFormatter: getValueFormatter(recordAdditionalFields.name, recordAdditionalFields.dataType),
                    renderCell: getRenderCell(
                        recordAdditionalFields.name,
                        undefined,
                        undefined,
                        recordAdditionalFields as unknown as IRecordField,
                        recordAdditionalFields.listType?.id ? listValuesByListId[Number(recordAdditionalFields.listType?.id)] : undefined
                    ),
                    renderEditCell: getRenderEditCellByType(
                        recordAdditionalFields.dataType,
                        recordAdditionalFields.listType?.id,
                        recordAdditionalFields.listType?.id ? listValuesByListId[Number(recordAdditionalFields.listType?.id)] : undefined
                    )
                } as GridColDef)
        );
    }, [listValuesByListId, lineItemHeaders]);

    const handleAddClick = () => {
        gridRef.current?.handleAddClick();
    };

    useImperativeHandle(ref, () => ({
        handleAddClick
    }));

    const handleProcessUpdate = async (newOne: any) => {
        try {
            let rowId: string | number = 0;
            const items = Object.keys(newOne)
                .filter((key) => !['id', 'order'].includes(key))
                .filter((key) => {
                    const newValue = newOne[key];
                    const oldData = getValueFromLineItemsData(
                        newOne.id,
                        lineItemsData?.findLineItems,
                        lineItemHeaders?.lineItemsByTypeFileds,
                        key
                    );

                    // This is needed to get the rowId even in the non created items
                    if (rowId === 0) rowId = oldData?.lineItemsRow?.id || 0;

                    return !oldData || newValue !== oldData.value;
                })
                .map((key) => {
                    const [fieldNameFromUpdated, fieldOrder] = key.split('-');
                    const idFromHeader = Number(
                        lineItemHeaders?.lineItemsByTypeFileds.find(
                            (el) => el.recordAdditionalFields.name === fieldNameFromUpdated && el.order === Number(fieldOrder)
                        )?.id
                    );

                    const oldData = getValueFromLineItemsData(
                        newOne.id,
                        lineItemsData?.findLineItems,
                        lineItemHeaders?.lineItemsByTypeFileds,
                        key
                    );

                    const id = Number(oldData?.id);

                    return id && idFromHeader
                        ? ({
                              id,
                              lineItemsByTypeFiledsId: idFromHeader,
                              value: newOne[key]
                          } as LineItemsFields)
                        : newOne[key] && idFromHeader
                        ? ({
                              lineItemsByTypeFiledsId: idFromHeader,
                              value: newOne[key]
                          } as LineItemsFields)
                        : null;
                })
                .filter((el) => el !== null);

            if (rowId !== 0) {
                await createOrUpdateByRow({
                    variables: {
                        data: {
                            index: Number(newOne.id),
                            lineItemsFields: items as LineItemsFields[],
                            recordHeaderId: recordId,
                            rowId: +rowId
                        }
                    }
                });
                handleSetLineItemUpdated();
            } else {
                const itemsWithRecordData = items.map((item) =>
                    item?.id
                        ? {
                              ...item,
                              id: item.id as number,
                              index: Number(newOne.id),
                              recordHeaderId: recordId
                          }
                        : {
                              ...item,
                              index: Number(newOne.id),
                              recordHeaderId: recordId
                          }
                );

                if (recordId !== 0) {
                    await Promise.all(
                        itemsWithRecordData
                            .filter((el) => el !== null)
                            .map((el) =>
                                // @ts-ignore
                                el.id ? updateLineItem({ variables: { data: el } }) : createLineItem({ variables: { data: el } })
                            )
                    );
                    handleSetLineItemUpdated();

                    return newOne;
                }
            }

            if (recordId !== 0) {
                await getLineItemData();
                handleSetLineItemUpdated();
            }

            setNewRows((prev) => prev.map((el) => (el.id === newOne.id ? newOne : el)));

            return newOne;
        } catch (error) {
            console.log('error changing values', error);
        }
        return null;
    };

    const handleProcessCreate = async (newOne: any) => {
        try {
            const index = newRows.slice(-1)[0]?.id + 1 || 1;
            const items = Object.keys(newOne)
                .filter((key) => !['id', 'autogenerate', 'order'].includes(key) && newOne[key])
                .map((key) => {
                    const [fieldNameFromUpdated, fieldOrder] = key.split('-');
                    const lineItemsByTypeFiledsId = Number(
                        lineItemHeaders?.lineItemsByTypeFileds.find(
                            (el) => el.recordAdditionalFields.name === fieldNameFromUpdated && el.order === Number(fieldOrder)
                        )?.id
                    );

                    return {
                        lineItemsByTypeFiledsId,
                        value: newOne[key]
                    } as LineItemsFields;
                });

            if (recordId !== 0) {
                await createOrUpdateByRow({
                    variables: { data: { index, lineItemsFields: items, recordHeaderId: recordId } }
                });
                handleSetLineItemUpdated();
            }

            setNewRows((prev) => [...prev, { ...newOne, id: index }]);
            if (recordId !== 0) {
                await getLineItemData();
                handleSetLineItemUpdated();
            }

            return { ...newOne, id: index };
        } catch (error) {
            console.log('error creating new line item', error);
            return null;
        }
    };

    const getLineItemData = useCallback(async () => {
        const { data } = await getLineItems({ fetchPolicy: 'network-only', variables: { data: { recordHeadersIds: [recordId] } } });
        const rows = generateLineItemRows(lineItemHeaders?.lineItemsByTypeFileds, data?.findLineItems);
        setNewRows(rows);
    }, [getLineItems, lineItemHeaders, recordId]);

    useEffect(() => {
        if (isOpen) getLineItemData();
    }, [getLineItemData, isOpen]);

    // This height is needed to the container get full height
    return (
        <Grid container sx={{ height: 'calc(100% - 52px)' }}>
            <Grid item xs={12} container spacing={1} height="100%" sx={{ position: 'relative' }}>
                <Fab
                    size="small"
                    sx={{ position: 'absolute', boxShadow: 'none !important', bgcolor: '#F5F6F7 !important', right: 0, top: '10px' }}
                    onClick={handleAddClick}
                >
                    <AddCircleOutline htmlColor="#858585" />
                </Fab>
                <GridWithInlineEdit
                    ref={gridRef}
                    GridComponent={StripedDataGrid}
                    density="compact"
                    onCreate={handleProcessCreate}
                    onUpdate={handleProcessUpdate}
                    loading={loadingMutations || loadingData || loadingListValues}
                    columns={headerList}
                    fieldToFocus={headerList[0]?.field || ''}
                    initialRows={newRows}
                    sx={{
                        '& .MuiTablePagination-toolbar': { py: 0 },
                        '& .MuiTablePagination-selectLabel, & > .MuiTablePagination-displayedRows': { margin: 0 }
                    }}
                    gridComponents={{
                        NoRowsOverlay: showCustomNoRowsOverlay ? () => <CustomNoRowsOverlay onAddNewItem={handleAddClick} /> : undefined
                    }}
                    recordId={recordId}
                    showQuickFilter
                    disabledReordering
                    disabledCheckboxSelection
                    shouldEdit
                    autosizeColumns
                    pinnedActions
                />
            </Grid>
        </Grid>
    );
});

export default LineItemsGrid;
