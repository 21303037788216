import { Search } from '@mui/icons-material';
import { Box, InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { getClientOS } from 'utils/getClientOS';

export type SearchBarProps = TextFieldProps & { placeholder?: string; hideKeys?: boolean };

export const SearchBar = ({ InputProps, placeholder, hideKeys, ...props }: SearchBarProps) => {
    const searchFieldRef = useRef<HTMLInputElement | null>(null);

    const keys = useMemo(() => {
        const os = getClientOS();
        if (os === 'MAC') return '⌘ + K';
        return 'Ctrl + K';
    }, []);

    const onFind = (e: KeyboardEvent) => {
        if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            console.log('Se presionó Command + K o Ctrl + K');
            searchFieldRef.current?.focus();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', onFind);

        return () => {
            document.removeEventListener('keydown', onFind);
        };
    });
    return (
        <TextField
            {...props}
            inputRef={searchFieldRef}
            variant="filled"
            InputProps={{
                hiddenLabel: true,
                disableUnderline: true,
                startAdornment: <Search htmlColor="#858585" sx={{ mr: 1 }} />,
                endAdornment: hideKeys ? undefined : (
                    <InputAdornment className="input-hot-keys" position="end" sx={{ height: '30px', transform: 'translateX(6px)' }}>
                        <Box sx={{ bgcolor: 'white', borderRadius: '50px', px: 1, display: 'flex', alignItems: 'center', height: '100%' }}>
                            <Typography color="#858585" fontSize="14px" fontWeight={500}>
                                {keys}
                            </Typography>
                        </Box>
                    </InputAdornment>
                ),
                placeholder: placeholder || 'Find in current page',
                ...(InputProps || {}),
                sx: { borderRadius: '50px', ...(InputProps?.sx || {}) }
            }}
        />
    );
};
