/* eslint-disable no-nested-ternary */
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, IconButton, SvgIcon, Typography } from '@mui/material';

// project imports
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
// import LocalizationSection from './LocalizationSection';
// import MegaMenuSection from './MegaMenuSection';
// import NotificationSection from './NotificationSection';
import { useSelector } from 'store';

// assets
import { ReactComponent as beamerIcon } from 'assets/images/icons/beamer-icon.svg';
import LogoSection from '../LogoSection';
import { useLocation } from 'react-router-dom';
import DashboardTitle from './DashboardTitle';

import { NotificationButton } from './FlexEndButtons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

export const OrderBahnHeader = () => {
    const theme = useTheme();
    const location = useLocation();

    const isDashboard = location.pathname.split('/').slice(2).join('/') === 'dashboard';

    const { sectionName: title } = useSelector((state) => state.section);
    const { sectionComponent } = useSelector((state) => state.section);
    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 260,
                    height: 88,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    backgroundColor: drawerOpen ? theme.palette.secondary.light : 'transparent',
                    paddingX: '2rem',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                {!drawerOpen && (
                    <Box
                        sx={{
                            height: '100%',
                            width: '20px',
                            backgroundColor: theme.palette.secondary.light,
                            position: 'absolute',
                            left: 0
                        }}
                    />
                )}
                <Box
                    component="span"
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    }}
                >
                    <LogoSection />
                </Box>
            </Box>

            <Box sx={{ flexGrow: 1, marginLeft: 'calc(260px + 2rem)' }}>
                {isDashboard ? (
                    <DashboardTitle />
                ) : (
                    <Typography
                        variant="h3"
                        color="secondary"
                        sx={{ display: 'flex', alignItems: 'center', mr: 1 }}
                        fontFamily="Poppins, sans-serif"
                    >
                        {title} {sectionComponent}
                    </Typography>
                )}
            </Box>
            {/* <Box sx={{ flexGrow: 1 }} /> */}
            <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <Box>
                    <IconButton
                        sx={{ color: '#858585', backgroundColor: theme.palette.primary[300], marginRight: '0.5rem' }}
                        className="beamerTrigger"
                        color="secondary"
                        size="large"
                    >
                        <SvgIcon component={beamerIcon} />
                    </IconButton>
                </Box>
                <NotificationButton />
            </Box>

            {/* <NewOrderDialog usersList={usersList} open={isOpenDialog} onClose={handleCloseDialog} onSubmit={saveChange} />

            <ManufacturerOrderDialog
                usersList={usersList}
                open={openManufacturerDialog}
                onClose={() => {
                    setOpenManufacturerDialog(false);
                }}
                onSubmit={saveChange}
            />

            <RfqDialog
                usersList={usersList}
                open={openRfqDialog}
                onClose={() => {
                    setOpenRfqDialog(false);
                }}
                onSubmit={saveChange}
            />

            <QuoteDialog
                usersList={usersList}
                open={openQuoteDialog}
                onClose={() => {
                    setOpenQuoteDialog(false);
                }}
                onSubmit={saveChange}
            /> */}

            {/* mega-menu */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <MegaMenuSection />
            </Box> */}

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <ProfileSection />

            {/* mobile header */}
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box>
        </>
    );
};
