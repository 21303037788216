import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { TextField, TextFieldProps } from '@mui/material';

interface CustomProps {
    inputRef: React.Ref<HTMLInputElement>;
    onChange: (...event: any[]) => void;
    name: string;
}

const TextMaskCustom = forwardRef<HTMLElement, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/
            }}
            inputRef={ref as React.Ref<HTMLInputElement>}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

interface InputMaskProps extends Omit<TextFieldProps, 'onChange'> {
    name: string;
    onChange: (...event: any[]) => void;
}

const InputMask: React.FC<InputMaskProps> = (props) => {
    const { name, onChange, ...other } = props;

    return (
        <TextField
            {...other}
            name={name}
            onChange={onChange}
            InputProps={{
                inputComponent: TextMaskCustom as any
            }}
        />
    );
};

export default InputMask;
