/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'store';
import { openDrawer } from 'store/slices/menu';
import { useCustomListValues } from 'hooks/useCustomListValues';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import { generateClearedRecordData, getListIdsFromHeaders, splitHeaders } from 'ui-component/records/utils/headerHelpers';
import { IObjectValues, IRecordField, IRecordHeaders } from 'ui-component/records/types';
import { IFormatDataValues, LogReportType } from '../types';
import { generateInitialState, getDataToCreate, getDataToUpdate } from '../utils';
import { getViewFields, getAditionalFieldsValues, getObjectValuesFromListValues, PanelTitle, LogReportMode, ButtonTypeLog } from './utils';
import EditPanel, { CreatePanelForm, EditPanelForm } from './EditPanel/v2';
import ViewPanel from './ViewPanel';
import { LogReportPanel } from './LogReportPanel';
import SideBar from './SideBar';
import { filterNotAllowedFields } from 'ui-component/records/utils';
import { useRecordData } from './hooks';
import ObjectPanelView from 'ui-component/records/RecordGrid/components/ObjectPanelView';
import ObjectPanelEditForm from 'ui-component/records/RecordGrid/components/ObjectPanelEditForm';
import { QUERY_GET_OBJECT_PROPERTIES } from 'graphql/queries/customObjects';
import { FindObjectProperty, FindObjectPropertyVariables, ObjectProperty } from 'views/backoffice/CustomObjects/types';
import { useLazyQuery } from '@apollo/client';
import { DynamicObject } from 'views/TenantProfileSettings/components/types';
import { generateCurrentValuesByPropertyId } from 'views/backoffice/CustomLists/utils';
import { FooterHistoryPanel } from './FooterHistoryPanel';
import { DROPDOWN_COLUMNS } from 'ui-component/records/utils/columnHelpers';
import { LogReportSelector } from './LogReportPanel/components';

export interface IForm {
    recordType?: number;
    recordNumber: string;
    statusId: number | null;
    problemCodeId: number | null;
    approvedBy: number | null;
    recordDate: string | null;
    dueDate: string | null;
    poNumber: string;
    assignedTo: number | null;
}

interface PropertiesPanelProps {
    loading?: boolean;
    recordData?: IRecordHeaders | null;
    recordType?: string;
    isCreate?: boolean;
    hasFile?: boolean;
    onSubmit: (recordType: number, data: IFormatDataValues, showAlerts: boolean) => Promise<boolean>;
    isEditMode?: any;
    onUploadFile: () => void;
    // PDF Controllers
    onToggleLineItems?: () => void;
    fileAdded?: boolean;
    onToggleComments?: () => void;
    onToggleAttachments?: () => void;
    showLogCommentsPanel?: boolean;
    setShowLogCommentsPanel?: (value: boolean) => void;
    showLineItemsPanel?: boolean;
    setShowLogLineItemsPanel?: (value: boolean) => void;
    recordId?: number;
}

export const PropertiesPanelv3 = ({
    loading,
    recordData = null,
    recordType,
    isCreate,
    onSubmit,
    onUploadFile,
    hasFile = false,
    isEditMode,
    onToggleLineItems,
    fileAdded = false,
    onToggleComments,
    onToggleAttachments,
    showLogCommentsPanel,
    setShowLogCommentsPanel,
    showLineItemsPanel,
    setShowLogLineItemsPanel,
    recordId
}: PropertiesPanelProps) => {
    const modal = useConfirmationModalContext();
    const dispatch = useDispatch();

    const [changePanel, setChangePanel] = useState<PanelTitle>(isCreate ? PanelTitle.Edit : PanelTitle.View);
    const [logReportMode, setLogReportMode] = useState<LogReportMode>(LogReportMode.All);
    const [showFullHistory, setShowFullHistory] = useState(false);
    const [recordTypeId, setRecordTypeId] = useState(recordType);
    const [initialFormState, setInitialFormState] = useState<Partial<CreatePanelForm>>({});

    const methods = useForm<CreatePanelForm | EditPanelForm>({
        mode: 'onChange'
    });

    const { fieldAccess, headers, loading: loadingRecordData } = useRecordData({ recordTypeId: Number(recordTypeId) });

    const checkAllowedFields = useMemo(() => filterNotAllowedFields(fieldAccess), [fieldAccess]);

    const { baseFieldHeaders, additionalFieldHeaders } = useMemo(
        () => splitHeaders(headers, checkAllowedFields),
        [checkAllowedFields, headers]
    );

    const isAttachmentFieldAllowed = Object.values(additionalFieldHeaders).filter((value) => value?.dataType === 'attachment');

    const listIds = getListIdsFromHeaders(headers);
    const { loading: loadingListValues, listValuesByListId } = useCustomListValues(listIds);

    // Objects States
    const [objectProperties, setObjectProperties] = useState<(ObjectProperty & { value: string })[]>([]);
    const [selectedObjectToEdit, setSelectedObjectToEdit] = useState<{ id: number; name: string } | null>(null);
    const [inMemoryObjectProperties, setInMemoryObjectProperties] = useState<{ [key: string]: any }>({});
    const [prevChangePanel, setPrevChangePanel] = useState<PanelTitle>(PanelTitle.View);
    const [isAllowedToEditObject, setIsAllowedToEditObject] = useState(true);

    const [buttonTypeLog, setButtonTypeLog] = useState<LogReportType>(ButtonTypeLog.Detail);

    // Objects Queries
    const [getObjectProperties, { loading: loadingObjectProperties }] = useLazyQuery<FindObjectProperty, FindObjectPropertyVariables>(
        QUERY_GET_OBJECT_PROPERTIES
    );
    // Objects Control
    const handleOpenViewObjectPanel = async (
        field: IRecordField,
        fieldName: string,
        editable: boolean,
        selectedObject?: Pick<DynamicObject, 'id' | 'objectValues' | 'objectDefinition'> | null
    ) => {
        const isListOfObjects = !!field?.listType?.objectDefinition?.id;

        const objectDefinitionIds = selectedObject
            ? +selectedObject.objectDefinition.id
            : isListOfObjects
            ? field.listType?.objectDefinition?.id
            : field.objectDefinition.id;

        const { data: objectPropertiesData } = await getObjectProperties({
            variables: { data: { objectDefinitionIds } }
        });

        // When the user selects an object but want to see it before save it in the field
        if (selectedObject) {
            const currentValuesByPropertyId = generateCurrentValuesByPropertyId(selectedObject.objectValues);
            setObjectProperties(
                objectPropertiesData?.findObjectProperty.map((el) => ({
                    ...el,
                    value: currentValuesByPropertyId[+el.id]?.value || ''
                })) || []
            );

            setIsAllowedToEditObject(false);
            setSelectedObjectToEdit({ id: 0, name: fieldName });
            setChangePanel(PanelTitle.ViewObject);
            return;
        }

        const fieldData = recordData?.additionalFields?.find((additionalField) => {
            const fieldId = additionalField.tag.split(';')[2];
            return Number(fieldId) === Number(field.id);
        });

        setSelectedObjectToEdit({ id: Number(fieldData?.id), name: fieldName });

        const valuesFromObjectList = getObjectValuesFromListValues(listValuesByListId, field?.listType?.id, fieldData?.value);

        const values = isListOfObjects ? (valuesFromObjectList as IObjectValues[]) : fieldData?.objectValue?.objectValues || [];

        if (!isCreate) {
            let mappedProperties = [];
            if (!inMemoryObjectProperties[fieldName]) {
                mappedProperties =
                    objectPropertiesData?.findObjectProperty
                        .map((property) => ({
                            ...property,
                            value: values.find((el) => Number(el.objectProperty.id) === Number(property.id))?.value || ''
                        }))
                        .filter((property) => property.enabled)
                        .sort((a, b) => {
                            const orderA = a.order; // ignore upper and lowercase
                            const orderB = b.order; // ignore upper and lowercase
                            if (orderA < orderB) {
                                return -1;
                            }
                            if (orderA > orderB) {
                                return 1;
                            }
                            return 0;
                        }) || [];
            } else {
                mappedProperties = inMemoryObjectProperties[fieldName];
            }
            setObjectProperties(mappedProperties || []);
        } else if (isCreate) {
            const mappedProperties = objectPropertiesData?.findObjectProperty
                .map((property) => ({
                    ...property,
                    value: ''
                }))
                .sort((a, b) => {
                    const orderA = a.order;
                    const orderB = b.order;
                    if (orderA < orderB) {
                        return -1;
                    }
                    if (orderA > orderB) {
                        return 1;
                    }
                    return 0;
                });
            setObjectProperties(inMemoryObjectProperties[fieldName] || mappedProperties);
        } else {
            setObjectProperties([]);
        }
        setIsAllowedToEditObject(editable);
        setPrevChangePanel(changePanel);
        setChangePanel(PanelTitle.ViewObject);
    };

    const handleUpdateObject = async (newProperties: (ObjectProperty & { value: string })[]) => {
        const stateInsert: { [key: string]: any } = {};
        stateInsert[String(selectedObjectToEdit?.name)] = newProperties;
        setInMemoryObjectProperties((prev) => ({ ...prev, ...stateInsert }));
        setPrevChangePanel(PanelTitle.View);
        setChangePanel(PanelTitle.Edit);
    };

    const handleSaveEditObjectFormActions = async (values: { [key: string]: string }, modalTitle: string) => {
        await modal.showConfirmation({
            title: modalTitle,
            content: '',
            loadingText: 'Saving Changes',
            forwardButtonText: 'Save changes',
            actionButtonText: 'Discard Changes',
            onForward: async () => {
                modal.toogleLoading(true);
                const newObjectProperties = objectProperties.map((item: ObjectProperty & { value: string }) => ({
                    ...item,
                    value: values[item.name]
                }));
                setObjectProperties(newObjectProperties);
                handleUpdateObject(newObjectProperties);
                if (!isCreate) {
                    const newProperties: { [key: string]: any } = {};
                    newProperties[String(selectedObjectToEdit?.name)] = newObjectProperties;
                    const formData = { ...methods.getValues(), ...inMemoryObjectProperties, ...newProperties };
                    const clearedData = generateClearedRecordData(initialFormState, formData);
                    await onSubmit(
                        Number(recordTypeId),
                        getDataToUpdate(clearedData, baseFieldHeaders, additionalFieldHeaders, recordData?.additionalFields || []),
                        true
                    );
                }
                modal.toogleLoading(false);
            },
            onAction: () => setChangePanel(prevChangePanel),
            showCheckbox: true,
            actionType: `${modalTitle === 'Do you want to save this changes?' ? `save` : `discard`}_object_changes`,
            sectionTitle: 'record_viewer',
            recordType: '',
            hideCloseBtn: true
        });
    };

    // Fields for view Panel
    const viewFields = useMemo(
        () => getViewFields(recordData, baseFieldHeaders, additionalFieldHeaders, fieldAccess, listValuesByListId),
        [additionalFieldHeaders, baseFieldHeaders, recordData, fieldAccess, listValuesByListId]
    );

    const toggleEditForm = () => {
        const newPanelState = changePanel === PanelTitle.Edit ? PanelTitle.View : PanelTitle.Edit;
        setChangePanel(newPanelState);
        if (newPanelState === PanelTitle.Edit) methods.reset({ ...initialFormState });
    };

    const toggleLogReport = () => {
        setChangePanel((s) => (s === PanelTitle.LogReport ? PanelTitle.View : PanelTitle.LogReport));
        switch (changePanel) {
            case PanelTitle.EditObject:
            case PanelTitle.ViewObject:
                setLogReportMode(LogReportMode.Objects);
                break;

            default:
                setLogReportMode(LogReportMode.All);
                break;
        }

        setShowLogCommentsPanel?.(false);
        setShowLogLineItemsPanel?.(false);
    };

    const handleOnToggleComments = () => {
        onToggleComments?.();
        setLogReportMode((prevMode) => (prevMode !== LogReportMode.Comments ? LogReportMode.Comments : LogReportMode.All));
    };

    const handleOnToggleLineItems = () => {
        onToggleLineItems?.();
        setLogReportMode((prevMode) => (prevMode !== LogReportMode.LineItems ? LogReportMode.LineItems : LogReportMode.All));
    };

    const handleOnToggleAttachments = () => {
        onToggleAttachments?.();
        setLogReportMode((prevMode) => (prevMode !== LogReportMode.Attachments ? LogReportMode.Attachments : LogReportMode.All));
    };

    const dynamicTitleFooterButton = useMemo(() => {
        if (logReportMode === LogReportMode.Comments) {
            return 'Show full comments history';
        }

        if (logReportMode === LogReportMode.LineItems) {
            return 'Show full line items history';
        }

        return 'Show full history';
    }, [logReportMode]);

    const handleClickCancel = async () => {
        const formData = methods.getValues();
        const clearedData = generateClearedRecordData(initialFormState, formData);
        if (Object.entries(clearedData).filter(([_key, val]) => val !== null).length === 0) setChangePanel(PanelTitle.View);
        else
            await modal.showConfirmation({
                title: 'Are you sure you want to discard your changes?',
                content: '',
                loadingText: 'Saving Changes',
                forwardButtonText: 'Discard Changes',
                actionButtonText: 'Save changes',
                onForward: async () => setChangePanel(PanelTitle.View),
                onAction: handleSubmit,
                showCheckbox: true,
                actionType: 'discard',
                sectionTitle: 'record_viewer',
                recordType: '',
                hideCloseBtn: true
            });
    };

    // Submit handlers
    const handleClickSave = async () => {
        if (await methods.trigger())
            await modal.showConfirmation({
                title: hasFile ? 'Do you want to save this changes?' : 'This record doesnt contain a document',
                content: '',
                loadingText: 'Saving Changes',
                forwardButtonText: hasFile ? 'Save changes' : 'Save Anyway',
                actionButtonText: hasFile ? 'Discard Changes' : 'Attach a Document',
                onForward: handleSubmit,
                onAction: hasFile ? async () => setChangePanel(PanelTitle.View) : onUploadFile,
                showCheckbox: true,
                actionType: isCreate ? 'create' : 'edit',
                sectionTitle: 'record_viewer_save',
                recordType: '',
                hideCloseBtn: true
            });
    };

    const handleSubmit = async () => {
        modal.toogleLoading(true);
        const formData = methods.getValues();
        const clearedData = generateClearedRecordData(initialFormState, formData);

        if (!isCreate && Object.entries(clearedData).filter(([_key, val]) => val !== null).length === 0 && !fileAdded) {
            setChangePanel(PanelTitle.View);
            return;
        }

        const res = await onSubmit(
            Number(recordTypeId),
            isCreate
                ? getDataToCreate(formData, baseFieldHeaders, additionalFieldHeaders, inMemoryObjectProperties)
                : getDataToUpdate(clearedData, baseFieldHeaders, additionalFieldHeaders, recordData?.additionalFields || []),
            true
        );
        modal.toogleLoading(false);
        if (res) setChangePanel(PanelTitle.View);
    };

    const handleToggleLogReport = () => {
        setChangePanel((s) => (s === PanelTitle.LogReport ? PanelTitle.View : PanelTitle.LogReport));
        setLogReportMode(LogReportMode.All);
    };

    // create form for edit record
    useEffect(() => {
        if (!recordData || !additionalFieldHeaders || !baseFieldHeaders || isCreate) return;

        const aditionalFields =
            additionalFieldHeaders && recordData?.additionalFields
                ? getAditionalFieldsValues(additionalFieldHeaders, recordData?.additionalFields)
                : {};
        const formState = Object.keys(baseFieldHeaders)
            .sort()
            .reduce((acc, key) => {
                const value =
                    ['string', 'number', 'boolean', 'date', 'datetime'].includes(baseFieldHeaders[key]?.dataType || '') &&
                    !DROPDOWN_COLUMNS.includes(key as (typeof DROPDOWN_COLUMNS)[number])
                        ? recordData[key as keyof IRecordHeaders]
                        : (recordData[key as keyof IRecordHeaders] as Record<string, any>)?.id;
                return { ...acc, [key]: value };
            }, {});

        setInitialFormState({
            ...formState,
            ...(aditionalFields || {})
        });
        methods.reset({
            ...formState,
            ...(aditionalFields || {})
        });
    }, [additionalFieldHeaders, recordData, baseFieldHeaders, isCreate, methods]);

    // create form for new record
    useEffect(() => {
        if (isCreate && baseFieldHeaders && changePanel === PanelTitle.Edit) {
            const initialState = generateInitialState(baseFieldHeaders, recordType);
            setInitialFormState(initialState);
            if (!methods.formState.defaultValues) methods.reset(initialState);
        }
    }, [baseFieldHeaders, changePanel, isCreate, methods, recordType]);

    useEffect(() => {
        if (!isEditMode) return;
        if (changePanel === PanelTitle.Edit) isEditMode(true);
        else isEditMode(false);
    }, [changePanel, isEditMode]);

    useEffect(() => {
        if (isCreate) return;
        if (showLogCommentsPanel) {
            setChangePanel(PanelTitle.LogReport);
            setLogReportMode(LogReportMode.Comments);
            setShowLogLineItemsPanel?.(false);
        } else if (showLineItemsPanel) {
            setChangePanel(PanelTitle.LogReport);
            setLogReportMode(LogReportMode.LineItems);
            setShowLogCommentsPanel?.(false);
        } else {
            setChangePanel(PanelTitle.View);
            setLogReportMode(LogReportMode.All);
            setShowLogCommentsPanel?.(false);
            setShowLogLineItemsPanel?.(false);
        }
    }, [showLogCommentsPanel, showLineItemsPanel, isCreate, setShowLogLineItemsPanel, setShowLogCommentsPanel]);

    const isObjectPanel = changePanel === PanelTitle.EditObject || changePanel === PanelTitle.ViewObject;

    return (
        <div
            data-testid="properties-panel"
            style={{
                height: '100%',
                display: isObjectPanel ? 'flex' : 'block',
                flexDirection: 'column',
                width: '100%',
                zIndex: 1000
            }}
        >
            <FormProvider {...methods}>
                <Box display="flex" flexDirection="row" sx={{ height: '100%', '& > div': { maxHeight: '100%' } }}>
                    <Box width={changePanel === PanelTitle.LogReport ? '100%' : 'calc(100% - 50px)'}>
                        <Box
                            sx={{
                                width: changePanel === PanelTitle.Edit || changePanel === PanelTitle.View ? '21vw' : '100%',
                                height: isCreate
                                    ? 'calc(100% - 5px)'
                                    : changePanel === PanelTitle.LogReport
                                    ? 'calc(100% - 60px)'
                                    : 'calc(100% - 5px)',
                                '& > *:first-child:not(#logReportSelectorId,#titleId)': { overflowY: 'auto', height: '100%' },
                                overflowY: isCreate ? 'auto' : 'scroll'
                            }}
                        >
                            <Typography
                                variant={isObjectPanel ? 'h4' : 'h3'}
                                component="div"
                                id="titleId"
                                sx={(theme) => ({
                                    paddingLeft: '30px',
                                    color: theme.palette.secondary.main,
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    letterSpacing: '0.15px',
                                    lineHeight: '24px'
                                })}
                            >
                                {isObjectPanel && (
                                    <Box
                                        onClick={() => {
                                            setPrevChangePanel(PanelTitle.View);
                                            setChangePanel(prevChangePanel);
                                        }}
                                        component="span"
                                        sx={(theme) => ({ color: theme.palette.primary[400], cursor: 'pointer' })}
                                    >
                                        Details {`> `}
                                    </Box>
                                )}
                                {changePanel === PanelTitle.LogReport
                                    ? ''
                                    : changePanel === PanelTitle.ViewObject || changePanel === PanelTitle.EditObject
                                    ? `${selectedObjectToEdit?.name ?? 'Object'}`
                                    : 'Details'}
                            </Typography>

                            {changePanel === PanelTitle.LogReport && (
                                <LogReportSelector
                                    onChangeSelection={setLogReportMode}
                                    toggleLogReport={toggleLogReport}
                                    buttonTypeLog={buttonTypeLog}
                                    setButtonTypeLog={setButtonTypeLog}
                                />
                            )}

                            {changePanel === PanelTitle.View && (
                                <ViewPanel
                                    fields={viewFields}
                                    loading={!!loading || loadingRecordData || loadingListValues}
                                    headerData={headers || null}
                                    handleOpenViewObjectPanel={handleOpenViewObjectPanel}
                                />
                            )}

                            {changePanel === PanelTitle.ViewObject && (
                                <ObjectPanelView
                                    objectName={`${selectedObjectToEdit?.name}`}
                                    loadingObjectProperties={loadingObjectProperties}
                                    handleClosePanel={() => {
                                        setChangePanel(PanelTitle.View);
                                        setObjectProperties([]);
                                    }}
                                    handleOpenEditPanel={() => {
                                        setChangePanel(PanelTitle.EditObject);
                                    }}
                                    objectProperties={objectProperties}
                                    isRecordViewer
                                    renderEditButton={isAllowedToEditObject}
                                    alwaysShowButtons
                                />
                            )}

                            {changePanel === PanelTitle.EditObject && (
                                <ObjectPanelEditForm
                                    objectName={`${selectedObjectToEdit?.name}`}
                                    showTopCloseButton
                                    objectProperties={objectProperties}
                                    handleUpdateObjectViewer={handleSaveEditObjectFormActions}
                                    handleClosePanelViewer={handleSaveEditObjectFormActions}
                                    isRecordViewer
                                />
                            )}

                            {(changePanel === PanelTitle.Edit ||
                                changePanel === PanelTitle.EditObject ||
                                changePanel === PanelTitle.ViewObject) && (
                                <EditPanel
                                    isCreate={isCreate}
                                    recordTypeId={recordType}
                                    onChangeRecordType={(val) => setRecordTypeId(val)}
                                    baseFieldHeaders={baseFieldHeaders}
                                    aditionalFieldHeader={additionalFieldHeaders}
                                    loadingFields={loadingRecordData || loadingListValues}
                                    fieldAccessList={fieldAccess}
                                    recordData={recordData}
                                    customListValues={listValuesByListId}
                                    inMemoryObjectProperties={inMemoryObjectProperties}
                                    handleOpenViewObjectPanel={handleOpenViewObjectPanel}
                                    isObjectPanelOpen={changePanel === PanelTitle.EditObject || changePanel === PanelTitle.ViewObject}
                                />
                            )}

                            {changePanel === PanelTitle.LogReport && (
                                <LogReportPanel
                                    recordId={Number(recordId)}
                                    showFullHistory={showFullHistory}
                                    onCloseFullHistory={() => setShowFullHistory(false)}
                                    logReportMode={logReportMode}
                                    onChangeSelection={setLogReportMode}
                                    setButtonTypeLog={setButtonTypeLog}
                                    buttonTypeLog={buttonTypeLog}
                                    toggleLogReport={handleToggleLogReport}
                                />
                            )}
                        </Box>
                        {changePanel === PanelTitle.LogReport && (
                            <FooterHistoryPanel
                                label={dynamicTitleFooterButton as unknown as string}
                                onShowFullHistory={() => {
                                    setShowFullHistory(true);
                                    dispatch(openDrawer(false));
                                }}
                            />
                        )}
                    </Box>
                    {(changePanel === PanelTitle.View || changePanel === PanelTitle.Edit) && (
                        <Box width="50px">
                            <SideBar
                                isCreate={isCreate}
                                changePanel={changePanel}
                                logReportMode={logReportMode}
                                toggleLogReport={toggleLogReport}
                                onToggleLineItems={handleOnToggleLineItems}
                                onToggleComments={handleOnToggleComments}
                                onToggleAttachments={handleOnToggleAttachments}
                                isObjectPanel={isObjectPanel}
                                onGoBack={() => {
                                    setPrevChangePanel(PanelTitle.View);
                                    setChangePanel(prevChangePanel);
                                }}
                                recordTypeId={Number(recordType)}
                                isAttachmentFieldAllowed={!!isAttachmentFieldAllowed?.length}
                                handleClickCancel={handleClickCancel}
                                handleClickSave={handleClickSave}
                                loading={loading || loadingRecordData}
                                toggleEditForm={toggleEditForm}
                                recordDataId={Number(recordId)}
                            />
                        </Box>
                    )}
                </Box>
            </FormProvider>
        </div>
    );
};

export default PropertiesPanelv3;
